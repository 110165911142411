<template>
    <div class="manageList">
        <el-card style="width: 100%;border-radius: 12px;margin: 16px;padding: 10px; ">
            <el-form :inline="true">
                <el-form-item label="手机号：">
                    <el-input style="width: 248px;" v-model="queryInfo.mobile" placeholder="请输入手机号" clearable />
                </el-form-item>
                <el-form-item label="姓名：">
                    <el-input style="width: 248px;" v-model="queryInfo.userName" placeholder="请输入姓名" clearable />
                </el-form-item>

                <el-form-item>
                    <el-button @click="reset">重置</el-button>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="search">搜索</el-button>
                </el-form-item>

            </el-form>
        </el-card>
        <el-card style="margin: 16px;border-radius: 12px;">

            <el-button style="margin-bottom:17px;" @click="exportExelsClick" icon="iconfont icon-jr-icon-out"
                type="primary">导出名单</el-button>
            <el-table :data="registTable" height="605px" :header-cell-style="cellStyle"
                @selection-change="handleSelectionChange" :row-key="getRowKeys" max-height="550">
                <el-table-column type="selection" width="55" :reserve-selection="true">
                </el-table-column>
                <el-table-column type="index" label="序号" align="center" width="100" />
                <el-table-column prop="headPortrait" label="头像" align="center">
                    <template v-slot="{ row }">
                        <el-image :src="row.headPortrait" style="width:80px;" />
                    </template>
                </el-table-column>
                <el-table-column prop="userName" label="姓名" align="center">

                </el-table-column>
                <el-table-column prop="mobile" label="手机号" align="center">

                </el-table-column>
                <el-table-column prop="weChatId" label="微信号" align="center">

                </el-table-column>
                <el-table-column prop="institution" label="机构" align="center">

                </el-table-column>
                <el-table-column prop="position" label="职位" align="center">

                </el-table-column>

                <el-table-column prop="createTime" label="报名时间" align="center">

                </el-table-column>
                <el-table-column prop="whetherMember" label="用户等级" align="center">
                    <template v-slot="{ row }">
                        <div>
                            {{ row.whetherMember == 0 ? '普通用户' : '会员用户' }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="操作">
                    <template slot-scope="scope">
                        <el-button size="mini" type="text" @click="handleClick(scope.row)">查看详情
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
             <div class="new_page">
            <el-pagination :current-page="queryInfo.current" :page-sizes="[3, 5, 10]" :page-size="queryInfo.size"
                layout="total, sizes, prev, pager, next, jumper" :total="queryInfo.total"
                @size-change="handleSizeChange" @current-change="handleCurrentChange" class="table_page" />
             </div>
        </el-card>
    </div>
</template>
<script>
import { getCustomerList, } from '@/api/activity.js'
import axios from 'axios'
export default {
    name: "manageList",
    data() {
        return {
            idList: [],
            isExportAll: true,
            queryInfo: {
                current: 1,
                id: 1,
                mobile: "",
                size: 10,
                userName: ""
            },
            registTable: [],
            cellStyle: {
                background: '#F6F6F6',
                color: '#333333'
            }

        }
    },
    computed: {
        Authorization() {
            return sessionStorage.getItem("token");
        },
        activityId() {
            return this.$route.query.id * 1
        },
    },
    created() {
        this.search()
    },
    methods: {
        //重置
        reset() {
            this.queryInfo.userName = ""
            this.queryInfo.mobile = ""
            this.search()
        },
        //搜索
        async search() {
            this.queryInfo.id = this.activityId
            const { data: res } = await getCustomerList(this.queryInfo)
            if (res.resultCode === 200) {
                this.registTable = res.data.list
                this.queryInfo.total = res.data.total
            }
        },
        exportExelsClick() {
            if (this.idList.length > 0) {
                const query = {
                    activityId: this.activityId,
                    idList: this.idList,
                    isExportAll: this.isExportAll
                }
                // if(!this.isExportAll){
                //     query.idList = []
                // }
                axios({
                    method: "POST",
                    url: `${process.env.VUE_APP_BASE_API}activity/exportExcel`,
                    data: query,
                    responseType: "blob",
                    headers: {
                        // 设置请求头
                        token: this.Authorization
                    },
                })
                    .then(res => {
                        let blob = new Blob([res.data], {
                            type: `application/vnd.ms-excel`,
                        });
                        let href = window.URL.createObjectURL(blob);
                        const a = document.createElement("a"); //创建a标签
                        a.style.display = "none";
                        a.href = href; // 指定下载'链接
                        a.download = "活动报名人员名单"; //指定下载文件名
                        a.click(); //触发下载
                        URL.revokeObjectURL(a.href); //释放URL对象
                        document.body.removeChild(a); //释放标签
                    })
                    .catch((err) => {
                        // console.log(err);
                    });
            } else {
                this.$message.warning('请选择您要操作的项！')
            }

        },
        handleSizeChange(val) {
            this.queryInfo.size = val
            this.search()
        },
        handleCurrentChange(val) {
            this.queryInfo.current = val
            this.search()
        },
        handleSelectionChange(val) {

            this.isExportAll = this.registTable.length == val.length ? true : false;
            // console.log(this.isExportAll);
            this.idList = val.map((item) => {
                return item.id
            })

        },

        handleClick(row) {
            this.$router.push({
                path: '/userManagement/userDetail?id=' + row.id,
            })
        },
        getRowKeys(row) {
            return row.idList
        }
    }

}
</script>
<style lang="less" scoped>
.manageList {
    width: 100%;
    overflow: auto;

    .new_page {
    width: 100%;
    text-align: center;
    margin: 30px auto;
  }
}
</style>

