import request from '@/utils/request'

//查询全部活动
export function activityList(data) {
    return request({
      url: '/activity/listPage',
      method: 'post',
      data
    })
  }
  //删除活动
  export function deleteList(data) {
    return request({
      url: '/activity/delete',
      method: 'get',
      params: data
    })
  }
  //新增活动
  export function getAddList(data) {
    return request({
      url: '/activity/add',
      method: 'post',
      data
    })
  }
  //修改活动信息
  export function getUpdateList(data) {
    return request({
      url: '/activity/update',
      method: 'post',
      data
    })
  }
   //获取详情
   export function detailList(data) {
    return request({
      url: '/activity/detail',
      method: 'get',
      params:data
    })
  }
   //报名管理
   export function getCustomerList(data) {
    return request({
      url: '/activity/listCustomerPage',
      method: 'post',
      data
    })
  }
 //省，市 区
 export function getCityAll(data) {
  return request({
    url: '/city/getCityAll',
    method: 'get',
    params:data
  })
}
  
//上传文件
export function uploadImg(data) {
  return request({
    url: '/upload/uploadOneFile',
    method: 'post',
    data
  })
}
export function getupdateState(data) {
  return request({
    url: '/activity/updateState',
    method: 'post',
    data
  })
}